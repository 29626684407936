<template>
  <div>
    <ca-header
      :heading="warrentyNumber"
      :loading="loading"
    >
      <template #buttonSpace>
        <router-link
          :to="{name: 'OrderDetail', params: {orderId: orderLog.orderId}}"
          class="btn btn-primary"
        >
          <i class="far fa-pen" /> Zur Garantie/Antrag
        </router-link>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div class="col-12 col-xl-8 col-xxl-6">
          <ca-card class="mb-3">
            <template #header>
              <div class="form-row">
                <div class="col">
                  <h4 class="mb-0">
                    Police / Anfrage
                  </h4>
                </div>
              </div>
            </template>
            <template #body>
              <div class="row">
                <div class="col-12 col-xl-8 col-xxl-6">
                  <h5 class="mb-0">
                    Informationen:
                  </h5>
                </div>
                <div class="col-12 col-xl-8 col-xxl-6">
                  <div class="row mb-4">
                    <div class="col-4">
                      Erstellt:
                    </div>
                    <div class="col-8">
                      {{ orderLog.createdAt | formatDate('DD.MM.YYYY HH:mm') }}
                    </div>
                  </div>
                </div>
                <template v-if="orderLog.type === 'FILE'">
                  <div class="col-12 col-xl-8 col-xxl-6">
                    <h5 class="mb-0">
                      Dateien:
                    </h5>
                  </div>
                  <div
                    v-for="file in orderLog.files"
                    :key="file._id"
                    class="col-12 col-xl-8 col-xxl-6"
                  >
                    <SignedDownloadLink
                      :file="file"
                    >
                      <template #default="{link}">
                        <a
                          :download="file.name"
                          :href="link"
                          class="btn btn-link my-1"
                          role="button"
                        >
                          Download von {{ file.name }}
                        </a>
                      </template>
                    </SignedDownloadLink>
                  </div>
                </template>
                <template v-if="orderLog.type === 'EMAIL'">
                  <div class="col-12 col-xl-8 col-xxl-6">
                    <h5 class="mb-0">
                      E-Mails:
                    </h5>
                  </div>
                  <div
                    v-for="email in orderLog.data"
                    :key="email._id"
                    class="col-12 col-xl-8 col-xxl-6"
                  >
                    <div class="row mb-4">
                      <div class="col-4">
                        Betreff:
                      </div>
                      <div class="col-8">
                        {{ email.envelope.subject }}
                      </div>
                      <div class="col-4">
                        Von:
                      </div>
                      <div class="col-8">
                        {{ email.envelope.from }}
                      </div>
                      <div class="col-4">
                        Zu:
                      </div>
                      <div class="col-8">
                        {{ email.envelope.to.join(', ') }}
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="orderLog.type === 'SMS'">
                  <div class="col-12 col-xl-8 col-xxl-6">
                    <h5 class="mb-0">
                      SMS:
                    </h5>
                  </div>
                  <div
                    v-for="sms in orderLog.data"
                    :key="sms._id"
                    class="col-12 col-xl-8 col-xxl-6"
                  >
                    <div class="row mb-4">
                      <div class="col-4">
                        Von:
                      </div>
                      <div class="col-8">
                        {{ sms.from }}
                      </div>
                      <div class="col-4">
                        Zu:
                      </div>
                      <div class="col-8">
                        {{ sms.to }}
                      </div>
                      <div class="col-4">
                        Text:
                      </div>
                      <div class="col-8">
                        {{ sms.body }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </ca-card>
        </div>
      </div>
    </div>
    <dev-info :data="{orderLog}" />
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import CaCard from '@/components/Card'
import SignedDownloadLink from '@/components/SignedDownloadLink'
import DevInfo from '@/components/DevInfo'
import getWarrentyNumber from '../../helpers/getWarrentyNumber'
import formatDate from '../../filters/formatDate.js'

export default {
  name: 'OrderDetail',
  components: {
    CaCard,
    DevInfo,
    CaHeader,
    SignedDownloadLink
  },
  filters: {
    formatDate
  },
  data () {
    return {
      orderLog: null,
      loading: false
    }
  },
  computed: {
    warrentyNumber () {
      return getWarrentyNumber(this.orderLog.order)
    },
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        this.orderLog = await this.$store.dispatch('order-log/get', this.$route.params.orderLogId)
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    hide (string, visibleChars = 4) {
      if (!string) return ''
      return string.substr(string.length - visibleChars).padStart(string.length, 'X')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
